@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.review {
  $block-name: &;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  // height: 535px;
  border: 2px solid $white-400;
  padding: 30px;
  text-decoration: none;
  border-radius: 12px;
  transition: transform $transition-time--slow;
  will-change: transform;

  @include mq($to: md) {
    // height: 421px;
    padding: 20px;
  }

  &:hover {
    transform: translateY(-12px);
    will-change: transform;
  }

  &:active {
    transform: translateY(-5px);
  }

  &__arrow-icon {
    color: $color-main;
    transition: transform $transition-time;
    will-change: transform;

    svg {
      stroke-width: 1.5;
    }
  }

  &__body {
    margin: 0;
  }

  &__star-icon {
    width: 17px;
    height: 17px;
    padding: 1px;
  }

  &__stars {
    font-size: 0;
    line-height: 0;
    display: flex;
    flex-wrap: nowrap;
    color: #ff4d39;
    margin: -1px;
  }

  &__text {
    display: -webkit-box;
    overflow: hidden;
    color: $black-300;
    font-family: $font-family--headings;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: -0.56px;
    margin-top: 0;
    margin-bottom: 0;
    text-overflow: ellipsis;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;

    @include mq($to: md) {
      font-size: 23px;
    }
  }

  &__top {
    display: flex;
    font-size: 0;
    line-height: 0;
    margin-bottom: 60px;
    align-items: center;
    justify-content: space-between;
  }

  &__top-left {
  }

  &__top-right {
    margin-left: 10px;
  }

  &__who {
    margin-top: 35px;

    @include mq($to: md) {
      margin-top: 25px;
    }
  }

  &__who-name {
    color: $color-main;
    font-family: $font-family--headings;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: -0.4px;
  }

  &__who-position {
    display: block;
    color: $dark-gray-400;
    font-family: $font-family;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-top: 2px;

    @include mq($to: md) {
      font-size: 14px;
    }
  }

  &--hover-variant-arrow-animate {
    &:hover {
      transform: translateY(0);
      will-change: transform;

      #{$block-name} {
        &__arrow-icon {
          transform: translate3d(4px, -4px, 0);
        }
      }
    }

    &:active {
      transform: translateY(0);

      #{$block-name} {
        &__arrow-icon {
          transform: translate3d(7px, -7px, 0);
        }
      }
    }
  }

  &--size-s {
    #{$block-name} {
      &__text {
        font-size: 20px;
      }

      &__who-name {
        font-size: 16px;
      }
    }
  }
}
